import { default as organizationsVdGJ6GDtGnMeta } from "/vercel/path0/apps/web/pages/admin/company/[slug]/organizations.vue?macro=true";
import { default as sales9ri3No8fxjMeta } from "/vercel/path0/apps/web/pages/admin/company/[slug]/sales.vue?macro=true";
import { default as subsidiary1mNcemAYkPMeta } from "/vercel/path0/apps/web/pages/admin/company/[slug]/subsidiary.vue?macro=true";
import { default as usersI9hzz4eXdKMeta } from "/vercel/path0/apps/web/pages/admin/company/[slug]/users.vue?macro=true";
import { default as viewdvVbwQmkoJMeta } from "/vercel/path0/apps/web/pages/admin/company/[slug]/view.vue?macro=true";
import { default as _91slug_93IColP8JZtOMeta } from "/vercel/path0/apps/web/pages/admin/company/[slug].vue?macro=true";
import { default as indexf1ojnWMu4ZMeta } from "/vercel/path0/apps/web/pages/admin/company/index.vue?macro=true";
import { default as indexnaiCwxtjQTMeta } from "/vercel/path0/apps/web/pages/admin/dashboard/index.vue?macro=true";
import { default as indexJdSPf7CtuvMeta } from "/vercel/path0/apps/web/pages/admin/team/index.vue?macro=true";
import { default as forgot_45passwordxUMXPMu2KSMeta } from "/vercel/path0/apps/web/pages/auth/forgot-password.vue?macro=true";
import { default as loginjHo7ReZn8GMeta } from "/vercel/path0/apps/web/pages/auth/login.vue?macro=true";
import { default as otp9sP9s43vxuMeta } from "/vercel/path0/apps/web/pages/auth/otp.vue?macro=true";
import { default as signupMV9NCzUMNCMeta } from "/vercel/path0/apps/web/pages/auth/signup.vue?macro=true";
import { default as verifyFy9EWmU8TUMeta } from "/vercel/path0/apps/web/pages/auth/verify.vue?macro=true";
import { default as _91slug_93iyfMaUokJDMeta } from "/vercel/path0/apps/web/pages/blog/[slug].vue?macro=true";
import { default as indexY5SzMVB5whMeta } from "/vercel/path0/apps/web/pages/blog/index.vue?macro=true";
import { default as indexqpAotu5DrUMeta } from "/vercel/path0/apps/web/pages/broker/dashboard/index.vue?macro=true";
import { default as indexkgiR9iN9B7Meta } from "/vercel/path0/apps/web/pages/broker/detail/[id]/client-docs/index.vue?macro=true";
import { default as indexhyXB4ZOI62Meta } from "/vercel/path0/apps/web/pages/broker/detail/[id]/client-info/index.vue?macro=true";
import { default as indexRH61N9ILkNMeta } from "/vercel/path0/apps/web/pages/broker/detail/[id]/doc-analyze/index.vue?macro=true";
import { default as indexoJuB7wRZPRMeta } from "/vercel/path0/apps/web/pages/broker/detail/[id]/index.vue?macro=true";
import { default as indexY4FPBPt1TNMeta } from "/vercel/path0/apps/web/pages/broker/detail/[id]/progress/index.vue?macro=true";
import { default as _91id_93fNQscNOWKdMeta } from "/vercel/path0/apps/web/pages/broker/detail/[id].vue?macro=true";
import { default as indexKZFQuElXtCMeta } from "/vercel/path0/apps/web/pages/broker/documents/[id]/client/index.vue?macro=true";
import { default as indexofzM78e4OsMeta } from "/vercel/path0/apps/web/pages/broker/documents/[id]/default/index.vue?macro=true";
import { default as indexRht0Ty7klZMeta } from "/vercel/path0/apps/web/pages/client/account/index.vue?macro=true";
import { default as indexhOCiFxF3oeMeta } from "/vercel/path0/apps/web/pages/client/dashboard/index.vue?macro=true";
import { default as indexnoTVIFuRulMeta } from "/vercel/path0/apps/web/pages/client/documents/index.vue?macro=true";
import { default as indexJmj3NtQ8GzMeta } from "/vercel/path0/apps/web/pages/client/index.vue?macro=true";
import { default as indexy7ze0ZQ3eMMeta } from "/vercel/path0/apps/web/pages/client/information/index.vue?macro=true";
import { default as index0CCHOSBCIoMeta } from "/vercel/path0/apps/web/pages/client/loan/apply/base-info/index.vue?macro=true";
import { default as indexavKgYHkwzbMeta } from "/vercel/path0/apps/web/pages/client/loan/apply/civil-doc/index.vue?macro=true";
import { default as indexVDOSQBnXSUMeta } from "/vercel/path0/apps/web/pages/client/loan/apply/client-info/index.vue?macro=true";
import { default as indexOWZXzPSB6oMeta } from "/vercel/path0/apps/web/pages/client/loan/apply/index.vue?macro=true";
import { default as indext39J5TzCYzMeta } from "/vercel/path0/apps/web/pages/client/loan/apply/loan-doc/index.vue?macro=true";
import { default as indexKekPJ7XEC5Meta } from "/vercel/path0/apps/web/pages/client/loan/apply/occupation/index.vue?macro=true";
import { default as indexUN21ZpRs2JMeta } from "/vercel/path0/apps/web/pages/client/loan/apply/reservation-doc/index.vue?macro=true";
import { default as applyerKYDfTwgkMeta } from "/vercel/path0/apps/web/pages/client/loan/apply.vue?macro=true";
import { default as indexK6NIftUlKLMeta } from "/vercel/path0/apps/web/pages/client/loan/dashboard/index.vue?macro=true";
import { default as indexLehKzJlhmpMeta } from "/vercel/path0/apps/web/pages/client/transaction/index.vue?macro=true";
import { default as clientsbhZZVUlvWVMeta } from "/vercel/path0/apps/web/pages/clients.vue?macro=true";
import { default as _91slug_93YKsCQyI8MdMeta } from "/vercel/path0/apps/web/pages/contact/[slug].vue?macro=true";
import { default as indexzNtaLWnWlWMeta } from "/vercel/path0/apps/web/pages/contact/base/list/index.vue?macro=true";
import { default as indexfETbxNSyaaMeta } from "/vercel/path0/apps/web/pages/contact/dashboard/index.vue?macro=true";
import { default as indexCocFnKQzedMeta } from "/vercel/path0/apps/web/pages/contact/denunciation/list/index.vue?macro=true";
import { default as index3uXY4TUsS4Meta } from "/vercel/path0/apps/web/pages/contact/denunciation/overview/list/index.vue?macro=true";
import { default as indexBrnjShRwAfMeta } from "/vercel/path0/apps/web/pages/contact/list/index.vue?macro=true";
import { default as indexlGG0KVuUtnMeta } from "/vercel/path0/apps/web/pages/contact/preferences/index.vue?macro=true";
import { default as _91slug_93kbEfhA0yjlMeta } from "/vercel/path0/apps/web/pages/contact/project/[slug].vue?macro=true";
import { default as indexwXlz6gKEnZMeta } from "/vercel/path0/apps/web/pages/contact/project/base/list/index.vue?macro=true";
import { default as index4xNqP6c19oMeta } from "/vercel/path0/apps/web/pages/contact/project/list/index.vue?macro=true";
import { default as ManagereiMIpCLTK4Meta } from "/vercel/path0/apps/web/pages/contact/project/list/Manager.vue?macro=true";
import { default as SellerXq9eEKqXunMeta } from "/vercel/path0/apps/web/pages/contact/project/list/Seller.vue?macro=true";
import { default as quick_searchxvm2aJL06eMeta } from "/vercel/path0/apps/web/pages/contact/project/quick_search.vue?macro=true";
import { default as featuresCdlkhQpJLbMeta } from "/vercel/path0/apps/web/pages/features.vue?macro=true";
import { default as indexZqlAvGc1GMMeta } from "/vercel/path0/apps/web/pages/index.vue?macro=true";
import { default as _91slug_939OZohohtcGMeta } from "/vercel/path0/apps/web/pages/legal/[slug].vue?macro=true";
import { default as index5mMK6DtDb5Meta } from "/vercel/path0/apps/web/pages/pretys/dashboard/index.vue?macro=true";
import { default as indexOpwZg1IN1MMeta } from "/vercel/path0/apps/web/pages/pretys/index.vue?macro=true";
import { default as indexeRovKM6vnqMeta } from "/vercel/path0/apps/web/pages/pretys/notifications/index.vue?macro=true";
import { default as indexSysIsbNt6UMeta } from "/vercel/path0/apps/web/pages/pretys/preferences/index.vue?macro=true";
import { default as _91id_93O55ej1WVgnMeta } from "/vercel/path0/apps/web/pages/pretys/transaction/detail/[id].vue?macro=true";
import { default as _91id_93bY2677kesIMeta } from "/vercel/path0/apps/web/pages/pretys/transaction/edit/[id].vue?macro=true";
import { default as indexgO9Q9lYcwRMeta } from "/vercel/path0/apps/web/pages/pretys/transaction/index.vue?macro=true";
import { default as indexu1wcNjkhcmMeta } from "/vercel/path0/apps/web/pages/pretys/transaction/list/index.vue?macro=true";
import { default as pricingwpMx5fuJliMeta } from "/vercel/path0/apps/web/pages/pricing.vue?macro=true";
import { default as indexVPyItELUV6Meta } from "/vercel/path0/apps/web/pages/prospere-plus/dashboard/index.vue?macro=true";
import { default as indexKM6Ub3FlXgMeta } from "/vercel/path0/apps/web/pages/prospere-plus/index.vue?macro=true";
import { default as indexHdCalTYNd2Meta } from "/vercel/path0/apps/web/pages/prospere-plus/program/[detail]/access-management/index.vue?macro=true";
import { default as index0y28q3ft3kMeta } from "/vercel/path0/apps/web/pages/prospere-plus/program/[detail]/base-info/index.vue?macro=true";
import { default as indexNJDuwcgsOuMeta } from "/vercel/path0/apps/web/pages/prospere-plus/program/[detail]/index.vue?macro=true";
import { default as indexw0fV2kRLdPMeta } from "/vercel/path0/apps/web/pages/prospere-plus/program/[detail]/lots/index.vue?macro=true";
import { default as _91slug_93mw9g0RTcjMMeta } from "/vercel/path0/apps/web/pages/prospere-plus/program/[detail]/lots/info/[slug].vue?macro=true";
import { default as indexp6zVRO6iGhMeta } from "/vercel/path0/apps/web/pages/prospere-plus/program/[detail]/program-docs/index.vue?macro=true";
import { default as _91detail_93KJcxzmsO0vMeta } from "/vercel/path0/apps/web/pages/prospere-plus/program/[detail].vue?macro=true";
import { default as indexkHCwl5cBgNMeta } from "/vercel/path0/apps/web/pages/prospere-plus/program/index.vue?macro=true";
import { default as index1oVt7J0iOCMeta } from "/vercel/path0/apps/web/pages/prospere-plus/program/list/index.vue?macro=true";
import { default as _settingsQmWm2rxWVvMeta } from "/vercel/path0/apps/web/pages/prospere/_settings.vue?macro=true";
import { default as indexk4L1A7MDblMeta } from "/vercel/path0/apps/web/pages/prospere/admin/index.vue?macro=true";
import { default as userscKyPsK1y0MMeta } from "/vercel/path0/apps/web/pages/prospere/admin/users.vue?macro=true";
import { default as adminY1miSjtD0HMeta } from "/vercel/path0/apps/web/pages/prospere/admin.vue?macro=true";
import { default as indexC3GpsprA2OMeta } from "/vercel/path0/apps/web/pages/prospere/ai-search/index.vue?macro=true";
import { default as indexKea5pgqznCMeta } from "/vercel/path0/apps/web/pages/prospere/classical-search/index.vue?macro=true";
import { default as indexZLZt5SnYA5Meta } from "/vercel/path0/apps/web/pages/prospere/dashboard/index.vue?macro=true";
import { default as indexhpF4bM1SEOMeta } from "/vercel/path0/apps/web/pages/prospere/favorites/index.vue?macro=true";
import { default as indexag9t0bYX2DMeta } from "/vercel/path0/apps/web/pages/prospere/index.vue?macro=true";
import { default as _91id_93QIECTKQojSMeta } from "/vercel/path0/apps/web/pages/prospere/lot/[id].vue?macro=true";
import { default as index22hVmozwT5Meta } from "/vercel/path0/apps/web/pages/prospere/preferences/index.vue?macro=true";
import { default as _91id_93GkPYskSrNdMeta } from "/vercel/path0/apps/web/pages/prospere/presentations/demos/[id].vue?macro=true";
import { default as _91id_93AjwlKSglFpMeta } from "/vercel/path0/apps/web/pages/prospere/presentations/detail/[id].vue?macro=true";
import { default as indexucYBQQQBUqMeta } from "/vercel/path0/apps/web/pages/prospere/presentations/list/index.vue?macro=true";
import { default as indexvPmVPHNFBqMeta } from "/vercel/path0/apps/web/pages/prospere/save-search/index.vue?macro=true";
import { default as generalwY6F9JsSgkMeta } from "/vercel/path0/apps/web/pages/prospere/settings/account/general.vue?macro=true";
import { default as indexEuEUzd8Gm7Meta } from "/vercel/path0/apps/web/pages/prospere/settings/index.vue?macro=true";
import { default as billing95E3P171qzMeta } from "/vercel/path0/apps/web/pages/prospere/settings/team/billing.vue?macro=true";
import { default as general2R5zRt3eYvMeta } from "/vercel/path0/apps/web/pages/prospere/settings/team/general.vue?macro=true";
import { default as members3f5JVQe1ECMeta } from "/vercel/path0/apps/web/pages/prospere/settings/team/members.vue?macro=true";
import { default as indexWCB5X7SCG4Meta } from "/vercel/path0/apps/web/pages/team/index.vue?macro=true";
export default [
  {
    name: "admin-company-slug",
    path: "/admin/company/:slug()",
    meta: _91slug_93IColP8JZtOMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/admin/company/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: "admin-company-slug-organizations",
    path: "organizations",
    component: () => import("/vercel/path0/apps/web/pages/admin/company/[slug]/organizations.vue").then(m => m.default || m)
  },
  {
    name: "admin-company-slug-sales",
    path: "sales",
    component: () => import("/vercel/path0/apps/web/pages/admin/company/[slug]/sales.vue").then(m => m.default || m)
  },
  {
    name: "admin-company-slug-subsidiary",
    path: "subsidiary",
    component: () => import("/vercel/path0/apps/web/pages/admin/company/[slug]/subsidiary.vue").then(m => m.default || m)
  },
  {
    name: "admin-company-slug-users",
    path: "users",
    component: () => import("/vercel/path0/apps/web/pages/admin/company/[slug]/users.vue").then(m => m.default || m)
  },
  {
    name: "admin-company-slug-view",
    path: "view",
    component: () => import("/vercel/path0/apps/web/pages/admin/company/[slug]/view.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin-company",
    path: "/admin/company",
    meta: indexf1ojnWMu4ZMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/admin/company/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: indexnaiCwxtjQTMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/admin/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-team",
    path: "/admin/team",
    meta: indexJdSPf7CtuvMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/admin/team/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordxUMXPMu2KSMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginjHo7ReZn8GMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-otp",
    path: "/auth/otp",
    meta: otp9sP9s43vxuMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/auth/otp.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: signupMV9NCzUMNCMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify",
    path: "/auth/verify",
    meta: verifyFy9EWmU8TUMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/auth/verify.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93iyfMaUokJDMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexY5SzMVB5whMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "broker-dashboard",
    path: "/broker/dashboard",
    meta: indexqpAotu5DrUMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/broker/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93fNQscNOWKdMeta?.name,
    path: "/broker/detail/:id()",
    component: () => import("/vercel/path0/apps/web/pages/broker/detail/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "broker-detail-id-client-docs",
    path: "client-docs",
    meta: indexkgiR9iN9B7Meta || {},
    component: () => import("/vercel/path0/apps/web/pages/broker/detail/[id]/client-docs/index.vue").then(m => m.default || m)
  },
  {
    name: "broker-detail-id-client-info",
    path: "client-info",
    meta: indexhyXB4ZOI62Meta || {},
    component: () => import("/vercel/path0/apps/web/pages/broker/detail/[id]/client-info/index.vue").then(m => m.default || m)
  },
  {
    name: "broker-detail-id-doc-analyze",
    path: "doc-analyze",
    meta: indexRH61N9ILkNMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/broker/detail/[id]/doc-analyze/index.vue").then(m => m.default || m)
  },
  {
    name: "broker-detail-id",
    path: "",
    component: () => import("/vercel/path0/apps/web/pages/broker/detail/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "broker-detail-id-progress",
    path: "progress",
    meta: indexY4FPBPt1TNMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/broker/detail/[id]/progress/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "broker-documents-id-client",
    path: "/broker/documents/:id()/client",
    meta: indexKZFQuElXtCMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/broker/documents/[id]/client/index.vue").then(m => m.default || m)
  },
  {
    name: "broker-documents-id-default",
    path: "/broker/documents/:id()/default",
    meta: indexofzM78e4OsMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/broker/documents/[id]/default/index.vue").then(m => m.default || m)
  },
  {
    name: "client-account",
    path: "/client/account",
    meta: indexRht0Ty7klZMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/client/account/index.vue").then(m => m.default || m)
  },
  {
    name: "client-dashboard",
    path: "/client/dashboard",
    meta: indexhOCiFxF3oeMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/client/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "client-documents",
    path: "/client/documents",
    meta: indexnoTVIFuRulMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/client/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "client",
    path: "/client",
    component: () => import("/vercel/path0/apps/web/pages/client/index.vue").then(m => m.default || m)
  },
  {
    name: "client-information",
    path: "/client/information",
    meta: indexy7ze0ZQ3eMMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/client/information/index.vue").then(m => m.default || m)
  },
  {
    name: applyerKYDfTwgkMeta?.name,
    path: "/client/loan/apply",
    component: () => import("/vercel/path0/apps/web/pages/client/loan/apply.vue").then(m => m.default || m),
    children: [
  {
    name: "client-loan-apply-base-info",
    path: "base-info",
    meta: index0CCHOSBCIoMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/client/loan/apply/base-info/index.vue").then(m => m.default || m)
  },
  {
    name: "client-loan-apply-civil-doc",
    path: "civil-doc",
    meta: indexavKgYHkwzbMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/client/loan/apply/civil-doc/index.vue").then(m => m.default || m)
  },
  {
    name: "client-loan-apply-client-info",
    path: "client-info",
    meta: indexVDOSQBnXSUMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/client/loan/apply/client-info/index.vue").then(m => m.default || m)
  },
  {
    name: "client-loan-apply",
    path: "",
    component: () => import("/vercel/path0/apps/web/pages/client/loan/apply/index.vue").then(m => m.default || m)
  },
  {
    name: "client-loan-apply-loan-doc",
    path: "loan-doc",
    meta: indext39J5TzCYzMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/client/loan/apply/loan-doc/index.vue").then(m => m.default || m)
  },
  {
    name: "client-loan-apply-occupation",
    path: "occupation",
    meta: indexKekPJ7XEC5Meta || {},
    component: () => import("/vercel/path0/apps/web/pages/client/loan/apply/occupation/index.vue").then(m => m.default || m)
  },
  {
    name: "client-loan-apply-reservation-doc",
    path: "reservation-doc",
    meta: indexUN21ZpRs2JMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/client/loan/apply/reservation-doc/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "client-loan-dashboard",
    path: "/client/loan/dashboard",
    meta: indexK6NIftUlKLMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/client/loan/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "client-transaction",
    path: "/client/transaction",
    meta: indexLehKzJlhmpMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/client/transaction/index.vue").then(m => m.default || m)
  },
  {
    name: "clients",
    path: "/clients",
    meta: clientsbhZZVUlvWVMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/clients.vue").then(m => m.default || m)
  },
  {
    name: "contact-slug",
    path: "/contact/:slug()",
    meta: _91slug_93YKsCQyI8MdMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/contact/[slug].vue").then(m => m.default || m)
  },
  {
    name: "contact-base-list",
    path: "/contact/base/list",
    meta: indexzNtaLWnWlWMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/contact/base/list/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-dashboard",
    path: "/contact/dashboard",
    meta: indexfETbxNSyaaMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/contact/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-denunciation-list",
    path: "/contact/denunciation/list",
    meta: indexCocFnKQzedMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/contact/denunciation/list/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-denunciation-overview-list",
    path: "/contact/denunciation/overview/list",
    meta: index3uXY4TUsS4Meta || {},
    component: () => import("/vercel/path0/apps/web/pages/contact/denunciation/overview/list/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-list",
    path: "/contact/list",
    meta: indexBrnjShRwAfMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/contact/list/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-preferences",
    path: "/contact/preferences",
    meta: indexlGG0KVuUtnMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/contact/preferences/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-project-slug",
    path: "/contact/project/:slug()",
    meta: _91slug_93kbEfhA0yjlMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/contact/project/[slug].vue").then(m => m.default || m)
  },
  {
    name: "contact-project-base-list",
    path: "/contact/project/base/list",
    meta: indexwXlz6gKEnZMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/contact/project/base/list/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-project-list",
    path: "/contact/project/list",
    meta: index4xNqP6c19oMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/contact/project/list/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-project-list-Manager",
    path: "/contact/project/list/Manager",
    component: () => import("/vercel/path0/apps/web/pages/contact/project/list/Manager.vue").then(m => m.default || m)
  },
  {
    name: "contact-project-list-Seller",
    path: "/contact/project/list/Seller",
    component: () => import("/vercel/path0/apps/web/pages/contact/project/list/Seller.vue").then(m => m.default || m)
  },
  {
    name: "contact-project-quick_search",
    path: "/contact/project/quick_search",
    meta: quick_searchxvm2aJL06eMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/contact/project/quick_search.vue").then(m => m.default || m)
  },
  {
    name: "features",
    path: "/features",
    meta: featuresCdlkhQpJLbMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexZqlAvGc1GMMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-slug",
    path: "/legal/:slug()",
    meta: _91slug_939OZohohtcGMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: "pretys-dashboard",
    path: "/pretys/dashboard",
    meta: index5mMK6DtDb5Meta || {},
    component: () => import("/vercel/path0/apps/web/pages/pretys/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "pretys",
    path: "/pretys",
    component: () => import("/vercel/path0/apps/web/pages/pretys/index.vue").then(m => m.default || m)
  },
  {
    name: "pretys-notifications",
    path: "/pretys/notifications",
    meta: indexeRovKM6vnqMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/pretys/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "pretys-preferences",
    path: "/pretys/preferences",
    meta: indexSysIsbNt6UMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/pretys/preferences/index.vue").then(m => m.default || m)
  },
  {
    name: "pretys-transaction-detail-id",
    path: "/pretys/transaction/detail/:id()",
    meta: _91id_93O55ej1WVgnMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/pretys/transaction/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "pretys-transaction-edit-id",
    path: "/pretys/transaction/edit/:id()",
    meta: _91id_93bY2677kesIMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/pretys/transaction/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "pretys-transaction",
    path: "/pretys/transaction",
    component: () => import("/vercel/path0/apps/web/pages/pretys/transaction/index.vue").then(m => m.default || m)
  },
  {
    name: "pretys-transaction-list",
    path: "/pretys/transaction/list",
    meta: indexu1wcNjkhcmMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/pretys/transaction/list/index.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingwpMx5fuJliMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "prospere-plus-dashboard",
    path: "/prospere-plus/dashboard",
    meta: indexVPyItELUV6Meta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere-plus/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "prospere-plus",
    path: "/prospere-plus",
    component: () => import("/vercel/path0/apps/web/pages/prospere-plus/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_93KJcxzmsO0vMeta?.name,
    path: "/prospere-plus/program/:detail()",
    component: () => import("/vercel/path0/apps/web/pages/prospere-plus/program/[detail].vue").then(m => m.default || m),
    children: [
  {
    name: "prospere-plus-program-detail-access-management",
    path: "access-management",
    meta: indexHdCalTYNd2Meta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere-plus/program/[detail]/access-management/index.vue").then(m => m.default || m)
  },
  {
    name: "prospere-plus-program-detail-base-info",
    path: "base-info",
    meta: index0y28q3ft3kMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere-plus/program/[detail]/base-info/index.vue").then(m => m.default || m)
  },
  {
    name: "prospere-plus-program-detail",
    path: "",
    component: () => import("/vercel/path0/apps/web/pages/prospere-plus/program/[detail]/index.vue").then(m => m.default || m)
  },
  {
    name: "prospere-plus-program-detail-lots",
    path: "lots",
    meta: indexw0fV2kRLdPMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere-plus/program/[detail]/lots/index.vue").then(m => m.default || m)
  },
  {
    name: "prospere-plus-program-detail-lots-info-slug",
    path: "lots/info/:slug()",
    meta: _91slug_93mw9g0RTcjMMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere-plus/program/[detail]/lots/info/[slug].vue").then(m => m.default || m)
  },
  {
    name: "prospere-plus-program-detail-program-docs",
    path: "program-docs",
    meta: indexp6zVRO6iGhMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere-plus/program/[detail]/program-docs/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "prospere-plus-program",
    path: "/prospere-plus/program",
    meta: indexkHCwl5cBgNMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere-plus/program/index.vue").then(m => m.default || m)
  },
  {
    name: "prospere-plus-program-list",
    path: "/prospere-plus/program/list",
    meta: index1oVt7J0iOCMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere-plus/program/list/index.vue").then(m => m.default || m)
  },
  {
    name: "prospere-_settings",
    path: "/prospere/_settings",
    component: () => import("/vercel/path0/apps/web/pages/prospere/_settings.vue").then(m => m.default || m)
  },
  {
    name: adminY1miSjtD0HMeta?.name,
    path: "/prospere/admin",
    component: () => import("/vercel/path0/apps/web/pages/prospere/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "prospere-admin",
    path: "",
    meta: indexk4L1A7MDblMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "prospere-admin-users",
    path: "users",
    meta: userscKyPsK1y0MMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere/admin/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "prospere-ai-search",
    path: "/prospere/ai-search",
    meta: indexC3GpsprA2OMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere/ai-search/index.vue").then(m => m.default || m)
  },
  {
    name: "prospere-classical-search",
    path: "/prospere/classical-search",
    meta: indexKea5pgqznCMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere/classical-search/index.vue").then(m => m.default || m)
  },
  {
    name: "prospere-dashboard",
    path: "/prospere/dashboard",
    meta: indexZLZt5SnYA5Meta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "prospere-favorites",
    path: "/prospere/favorites",
    meta: indexhpF4bM1SEOMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "prospere",
    path: "/prospere",
    component: () => import("/vercel/path0/apps/web/pages/prospere/index.vue").then(m => m.default || m)
  },
  {
    name: "prospere-lot-id",
    path: "/prospere/lot/:id()",
    meta: _91id_93QIECTKQojSMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere/lot/[id].vue").then(m => m.default || m)
  },
  {
    name: "prospere-preferences",
    path: "/prospere/preferences",
    meta: index22hVmozwT5Meta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere/preferences/index.vue").then(m => m.default || m)
  },
  {
    name: "prospere-presentations-demos-id",
    path: "/prospere/presentations/demos/:id()",
    meta: _91id_93GkPYskSrNdMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere/presentations/demos/[id].vue").then(m => m.default || m)
  },
  {
    name: "prospere-presentations-detail-id",
    path: "/prospere/presentations/detail/:id()",
    meta: _91id_93AjwlKSglFpMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere/presentations/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "prospere-presentations-list",
    path: "/prospere/presentations/list",
    meta: indexucYBQQQBUqMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere/presentations/list/index.vue").then(m => m.default || m)
  },
  {
    name: "prospere-save-search",
    path: "/prospere/save-search",
    meta: indexvPmVPHNFBqMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere/save-search/index.vue").then(m => m.default || m)
  },
  {
    name: "prospere-settings-account-general",
    path: "/prospere/settings/account/general",
    meta: generalwY6F9JsSgkMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere/settings/account/general.vue").then(m => m.default || m)
  },
  {
    name: "prospere-settings",
    path: "/prospere/settings",
    meta: indexEuEUzd8Gm7Meta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "prospere-settings-team-billing",
    path: "/prospere/settings/team/billing",
    meta: billing95E3P171qzMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere/settings/team/billing.vue").then(m => m.default || m)
  },
  {
    name: "prospere-settings-team-general",
    path: "/prospere/settings/team/general",
    meta: general2R5zRt3eYvMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere/settings/team/general.vue").then(m => m.default || m)
  },
  {
    name: "prospere-settings-team-members",
    path: "/prospere/settings/team/members",
    meta: members3f5JVQe1ECMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/prospere/settings/team/members.vue").then(m => m.default || m)
  },
  {
    name: "team",
    path: "/team",
    meta: indexWCB5X7SCG4Meta || {},
    component: () => import("/vercel/path0/apps/web/pages/team/index.vue").then(m => m.default || m)
  }
]
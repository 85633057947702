/* eslint-disable @typescript-eslint/no-unused-vars */
import type { ApiOutput } from "api/modules/trpc/router";
import type { Company } from "database";
import { useCompany } from "~/modules/pretys/company/composables/useCompany";
import { useOrganization } from "~/modules/team/composables/useTeamStore";

type User = ApiOutput["auth"]["user"];

type MiddlewareConfig = {
  pathPrefix: string;
  condition: (user: User | null, company: Company) => boolean;
  redirectPath: string;
};

const middlewareConfigs: MiddlewareConfig[] = [
  {
    pathPrefix: "/client",
    condition: (user) => user?.pretysRole !== "CLIENT",
    redirectPath: "/",
  },
  {
    pathPrefix: "/prospere-plus",
    condition: (user, company) =>
      !company || !company.companyType.startsWith("PROMOTEUR"),
    redirectPath: "/",
  },
  {
    pathPrefix: "/prospere",
    condition: (user) =>
      user?.isSubscribed === false && user?.pretysRole !== "CLIENT",
    redirectPath: "/prospere/settings/team/billing",
  },
  {
    pathPrefix: "/prospere",
    condition: (user) => user?.pretysRole === "CLIENT",
    redirectPath: "/client/dashboard",
  },
  {
    pathPrefix: "/pretys",
    condition: (user, company) => company === null,
    redirectPath: "/",
  },
  {
    pathPrefix: "/contact",
    condition: (user, company) => company === null,
    redirectPath: "/client",
  },
  {
    pathPrefix: "/team",
    condition: (user, company) => company === null,
    redirectPath: "/client",
  },
  {
    pathPrefix: "/admin",
    condition: (user) => user?.pretysRole !== "ADMIN",
    redirectPath: "/",
  },
];

export default defineNuxtRouteMiddleware(async (to, _from) => {
  const { user, reloadUser } = useUser();
  // load user
  if (!user?.value) {
    await reloadUser();
  }
  // loop through middlewareConfigs
  for (const config of middlewareConfigs) {
    const regex = new RegExp(`^${config.pathPrefix}(/|$)`);
    if (to.path.match(regex)) {
      const { currentCompany, loadCompany } = useCompany();
      const { currentOrganization, loadOrganization } = useOrganization();
      const currentTeamId = useCurrentTeamIdCookie();

      // load company
      if (user.value && currentCompany.value === null) {
        await loadCompany();
      }
      // load organization
      if (user.value && currentOrganization.value === null) {
        await loadOrganization();
      }
      // get current team id
      if (user.value) {
        const teamMemberships = user.value?.teamMemberships ?? [];
        const currentTeam = teamMemberships[0]?.teamId;
        currentTeamId.value = currentTeam;
      }
      // check if user is allowed to access the page
      if (
        config.condition(user?.value ?? null, currentCompany.value) === true
      ) {
        if (to.path !== config.redirectPath)
          return navigateTo(config.redirectPath);
        else return;
      }
    }
  }

  return;
});

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <Toast />
    <NuxtLoadingIndicator />
  </div>
</template>

<script setup lang="ts">
import Toast from "primevue/toast";
const titleTemplateDefault = "Prospere.nuxt - Application";
const titleDivider = "|";

const i18nHead = useLocaleHead({
  addSeoAttributes: true,
});

const { init } = useAnalytics();

// You might want to display a consent banner before initializing analytics
init();

useHead({
  // general seo
  titleTemplate: (title) =>
    title
      ? `${title} ${titleDivider} ${titleTemplateDefault}`
      : titleTemplateDefault,

  // i18n
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [...(i18nHead.value.meta || [])],
});
</script>

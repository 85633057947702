export default {
  root: ({ props }) => ({
    class: [
      "rounded-md",

      // Colors
      { "bg-surface-0 dark:bg-surface-900": !props.disabled },
      "text-surface-700 dark:text-white/80",
      "border",
      { "border-surface-300 dark:border-surface-700": !props.invalid },

      // Disabled State
      {
        "bg-surface-200 dark:bg-surface-700 select-none pointer-events-none cursor-default":
          props.disabled,
      },

      // Invalid State
      { "border-red-500 dark:border-red-400": props.invalid },
    ],
  }),
  listContainer: "overflow-auto",
  list: {
    class: "p-1 list-none m-0 outline-none",
  },
  option: ({ context }) => ({
    class: [
      "relative",

      // Flex
      "flex items-center",

      // Font
      "leading-none",

      // Spacing
      "m-0 px-3 py-2",
      "first:mt-0 mt-[2px]",

      // Shape
      "border-0 rounded",

      // Colors
      {
        "text-surface-700 dark:text-white/80":
          !context.focused && !context.selected,
        "bg-surface-200 dark:bg-surface-600/60":
          context.focused && !context.selected,
        "text-surface-700 dark:text-white/80":
          context.focused && !context.selected,
        "bg-highlight": context.selected,
      },

      //States
      {
        "hover:bg-surface-100 dark:hover:bg-[rgba(255,255,255,0.03)]":
          !context.focused && !context.selected,
      },
      { "hover:bg-highlight-emphasis": context.selected },
      {
        "hover:text-surface-700 hover:bg-surface-100 dark:hover:text-white dark:hover:bg-[rgba(255,255,255,0.03)]":
          context.focused && !context.selected,
      },

      // Transition
      "transition-shadow duration-200",

      // Misc
      "cursor-pointer overflow-hidden whitespace-nowrap",
    ],
  }),
  optionGroup: {
    class: [
      "font-semibold",

      // Spacing
      "m-0 py-2 px-3",

      // Colors
      "text-surface-400 dark:text-surface-500",

      // Misc
      "cursor-auto",
    ],
  },
  optionCheckIcon:
    "relative -ms-1.5 me-1.5 text-surface-700 dark:text-white/80 w-4 h-4",
  emptyMessage: {
    class: [
      // Font
      "leading-none",

      // Spacing
      "py-2 px-3",

      // Color
      "text-surface-800 dark:text-white/80",
      "bg-transparent",
    ],
  },
  header: {
    class: [
      // Spacing
      "pt-2 px-2 pb-0",
      "m-0",

      //Shape
      "border-b-0",
      "rounded-tl-md",
      "rounded-tr-md",

      // Color
      "text-surface-700 dark:text-white/80",
      "bg-surface-0 dark:bg-surface-900",
      "border-surface-300 dark:border-surface-700",

      "[&_[data-pc-name=pcfilter]]:w-full",
    ],
  },
};

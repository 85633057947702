export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, _instance, _info) => {
    console.log(
      "🚀 ~ defineNuxtPlugin ~ error, instance, info:",
      error
      // instance,
      // info
    );
    // handle error, e.g. report to a service
  };

  // Also possible
  nuxtApp.hook("vue:error", (error, _instance, _info) => {
    console.log(
      "🚀 ~ nuxtApp.hook [vue:error] ~ error, instance, info:",
      error
    );
  });
});

import { useBroadcastChannel } from "@vueuse/core";
import type { ApiOutput } from "api/modules/trpc/router";

type User = ApiOutput["auth"]["user"];
type AuthEvent = {
  type: "loaded" | "logout";
  user: User | null;
};

export const useUser = ({ initialUser }: { initialUser?: User } = {}) => {
  const { apiCaller } = useApiCaller();
  const localePath = useLocalePath();
  const currentTeamId = useCurrentTeamIdCookie();

  // create a broadcast channel for auth events
  const authBroadcastChannel = useBroadcastChannel<AuthEvent, AuthEvent>({
    name: "auth",
  });

  // post message to the channel with type and user
  const postChannelMessage = (message: AuthEvent) => {
    const cleanupMessage = JSON.parse(JSON.stringify(message)) as AuthEvent;
    authBroadcastChannel.post(cleanupMessage);
  };

  // initial state
  const loaded = useState("useUser-loaded", () => !!initialUser);

  const user = useState("useUser-user", () => initialUser ?? null);

  const teamMemberships = computed(() => {
    return user.value?.teamMemberships ?? [];
  });

  const currentTeamMemberships = computed(() =>
    teamMemberships.value.find((membership) => {
      return membership.team.id === currentTeamId.value;
    })
  );

  const currentTeam = computed(
    () => currentTeamMemberships.value?.team ?? null
  );

  const teamRole = computed(
    () => currentTeamMemberships.value?.role ?? "MEMBER"
  );

  // api call to load user
  const loadUser = async () => {
    const userRes = await apiCaller.auth.user.query();
    user.value = userRes;
    loaded.value = !!userRes;
  };

  // Post loaded message to the channel
  watchEffect(() => {
    if (user.value && loaded.value) {
      postChannelMessage({
        type: "loaded",
        user: user.value,
      });
    }
  });

  // Handle auth event, if user is different from the current user, update the user
  watch(authBroadcastChannel.data, (data) => {
    if (JSON.stringify(data.user) !== JSON.stringify(user.value)) {
      if (data.type === "logout") {
        user.value = null;
        loaded.value = false;

        navigateTo(localePath("/"));
      } else {
        user.value = data.user;
        loaded.value = true;
      }
    }
  });

  // reload user
  const reloadUser = async () => {
    await loadUser();
  };

  // logout
  const logout = async () => {
    await apiCaller.auth.logout.mutate();
    user.value = null;
    loaded.value = false;

    postChannelMessage({
      type: "logout",
      user: null,
    });

    navigateTo(localePath("/"));
  };
  const logoutAfterChangeMail = async () => {
    user.value = null;
    loaded.value = false;

    postChannelMessage({
      type: "logout",
      user: null,
    });

    navigateTo(localePath("/"));
  };

  return {
    user,
    loaded,
    teamMemberships,
    logout,
    logoutAfterChangeMail,
    reloadUser,
    currentTeam,
    teamRole,
  };
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("loading", {
    mounted(el, binding) {
      const loadingElement = document.createElement("div");
      loadingElement.className = "v-loading-indicator";
      loadingElement.innerHTML = `
        <style>
          .v-loading-indicator {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9999;
            pointer-events: all;
          }
          .v-loading-indicator.global {
            position: fixed;
          }
          .v-loading-spinner {
            width: 40px;
            height: 40px;
            border: 4px solid #f3f3f3;
            border-top: 4px solid #5a88e1;
            border-radius: 50%;
            animation: spin 1s linear infinite;
          }
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        </style>
        <div class="v-loading-spinner"></div>
      `;

      const isGlobal = binding.arg === "global";

      if (isGlobal) {
        loadingElement.classList.add("global");
      } else {
        el.style.position = "relative";
      }

      const update = (value: boolean) => {
        if (value) {
          if (isGlobal) {
            document.body.appendChild(loadingElement);
          } else {
            el.appendChild(loadingElement);
          }
        } else {
          loadingElement.remove();
        }
      };

      update(binding.value);

      (el as any)._vLoading = { loadingElement, update, isGlobal };
    },
    updated(el, binding) {
      if (binding.value !== binding.oldValue) {
        (el as any)._vLoading.update(binding.value);
      }
    },
    unmounted(el) {
      (el as any)._vLoading.loadingElement.remove();
      delete (el as any)._vLoading;
    },
  });
});

import type { Organization } from "database/src/zod";

export const useOrganization = () => {
  const { apiCaller } = useApiCaller();

  // pretys current organization state
  const currentOrganization = useState(
    "useOrganization-currentOrganization",
    () => null as unknown as Organization
  );

  // for directors and managers.
  const managedOrganizations = useState(
    "useOrganization-managedOrganizations",
    () => [] as Organization[]
  );

  // for salers.
  const userInternalOrganizations = useState(
    "useOrganization-userInternalOrganizations",
    () => [] as Organization[]
  );

  // for prescribers.
  const userExternalOrganizations = useState(
    "useOrganization-userExternalOrganizations",
    () => [] as Organization[]
  );

  // api call to load organization
  const loadOrganization = async () => {
    const organizationRes =
      await apiCaller.pretys.organization.getOrganizationInfo.query();
    managedOrganizations.value = organizationRes.managedOrganizations;
    userInternalOrganizations.value = organizationRes.userInternalOrganizations;
    userExternalOrganizations.value = organizationRes.userExternalOrganizations;
    if (currentOrganization.value === null) {
      if (managedOrganizations.value.length > 0)
        currentOrganization.value = managedOrganizations.value[0];
      else if (userInternalOrganizations.value.length > 0)
        currentOrganization.value = userInternalOrganizations.value[0];
      else if (userExternalOrganizations.value.length > 0)
        currentOrganization.value = userExternalOrganizations.value[0];
    }
  };

  // set current organization
  const setCurrentOrganization = (organization: Organization) => {
    currentOrganization.value = organization;
  };

  return {
    currentOrganization,
    managedOrganizations,
    userInternalOrganizations,
    userExternalOrganizations,
    loadOrganization,
    setCurrentOrganization,
  };
};

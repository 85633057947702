import revive_payload_client_U2HZ7Bk9zL from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1_2omfcrqve76b3pvkpkvkdj7hpm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_4dRCpMqh64 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1_2omfcrqve76b3pvkpkvkdj7hpm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_v0mzONS1bn from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1_2omfcrqve76b3pvkpkvkdj7hpm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_PdmkyC3RPB from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.16_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@20.16.5_terser@5._bjxyu7xqo5at3j3kira2j7knli/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_1UiIdNHDDX from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1_2omfcrqve76b3pvkpkvkdj7hpm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_N9CWJ0oO6P from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1_2omfcrqve76b3pvkpkvkdj7hpm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_M8yefG2NtF from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1_2omfcrqve76b3pvkpkvkdj7hpm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_f9qrmY5OR7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1_2omfcrqve76b3pvkpkvkdj7hpm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_oAKv1MKiWt from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1_2omfcrqve76b3pvkpkvkdj7hpm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/vercel/path0/apps/web/.nuxt/primevue-plugin.mjs";
import plugin_client_8PGTdrkTxd from "/vercel/path0/node_modules/.pnpm/@primevue+nuxt-module@4.0.5_@babel+parser@7.25.6_magicast@0.3.5_rollup@4.21.2_vue@3.4.38_typescript@5.5.4_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import plugin_ziYcKwnJdq from "/vercel/path0/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_69dc5kmzhj from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.2_@parcel+watcher@2.4.1_@types+node@20.16.5_h3@1.12.0_ioredis@5.4.1_jiti@1_yj4joiukm6y4z5vtqracigwb2i/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_88cpzxDXld from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.5_rollup@4.21.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_ELLwDeFSSF from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.2_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_6jMpxGqx9m from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.2_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import loading_directive_ADsfcw3KBv from "/vercel/path0/apps/web/plugins/loading-directive.ts";
import event_handler_Dp0fxSn7BE from "/vercel/path0/apps/web/plugins/event-handler.ts";
export default [
  revive_payload_client_U2HZ7Bk9zL,
  unhead_4dRCpMqh64,
  router_v0mzONS1bn,
  _0_siteConfig_PdmkyC3RPB,
  payload_client_1UiIdNHDDX,
  navigation_repaint_client_N9CWJ0oO6P,
  check_outdated_build_client_M8yefG2NtF,
  chunk_reload_client_f9qrmY5OR7,
  components_plugin_KR1HBZs4kY,
  prefetch_client_oAKv1MKiWt,
  primevue_plugin_egKpok8Auk,
  plugin_client_8PGTdrkTxd,
  plugin_ziYcKwnJdq,
  plugin_69dc5kmzhj,
  plugin_client_88cpzxDXld,
  switch_locale_path_ssr_ELLwDeFSSF,
  i18n_6jMpxGqx9m,
  loading_directive_ADsfcw3KBv,
  event_handler_Dp0fxSn7BE
]
import type { Company } from "database/src/zod";

export const useCompany = () => {
  const { apiCaller } = useApiCaller();
  // pretys company state
  const currentCompany = useState(
    "useCompany-currentCompany",
    () => null as unknown as Company
  );

  // for directors and managers.
  const managedCompanies = useState(
    "useCompany-managedCompanies",
    () => [] as Company[]
  );
  // for salers.
  const userInternalCompanies = useState(
    "useCompany-userInternalCompanies",
    () => [] as Company[]
  );
  // for prescribers.
  const userExternalCompanies = useState(
    "useCompany-userExternalCompanies",
    () => [] as Company[]
  );

  // api call to load company
  const loadCompany = async () => {
    const companyRes = await apiCaller.pretys.company.companyInfo.query();
    managedCompanies.value = companyRes.managedCompanies;
    userInternalCompanies.value = companyRes.userInternalCompanies;
    userExternalCompanies.value = companyRes.userExternalCompanies;
    if (currentCompany.value === null) {
      if (managedCompanies.value.length > 0)
        currentCompany.value = managedCompanies.value[0];
      else if (userInternalCompanies.value.length > 0)
        currentCompany.value = userInternalCompanies.value[0];
      else if (userExternalCompanies.value.length > 0)
        currentCompany.value = userExternalCompanies.value[0];
    }
  };

  // set current company
  const setCurrentCompany = (company: Company) => {
    currentCompany.value = company;
  };

  return {
    currentCompany,
    managedCompanies,
    userInternalCompanies,
    userExternalCompanies, // check cette liste, si c'est pas vide, ca veut dire que il faut afficher la liste promoteurs, sinon afficher les programmes qu il y a dans le current company
    loadCompany,
    setCurrentCompany,
  };
};

// get programList quand le mec il a un current company
